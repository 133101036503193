import { useEditContext } from "react-admin";

import ClientForm from "./ClientForm";

const ClientFormEdit = () => {
	//We call this form either from the CreateContext or the EditContext, the fields will be slightly different depending on whether or not there's a record
	const { record, isLoading } = useEditContext();

	return <ClientForm record={record} contextLoadin={isLoading} />;
};

export default ClientFormEdit;
