import { Fragment } from "react";
import {
	List,
	Datagrid,
	TextField,
	DateField,
	EditButton,
	TextInput,
	useRecordContext,
	BulkExportButton,
	BulkDeleteButton,
	TopToolbar,
	CreateButton,
	ExportButton,
	FilterButton,
} from "react-admin";
import ClientNameField from "../../Fields/ClientName";
import RelatedClients from "../../Components/RelatedClients";
import WarningIcon from "@mui/icons-material/Warning";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import useOpenHAT from "../../Hooks/useOpenHAT";
import SegmentsInput from "../../Fields/SegmentsInput";

const ClientBulkActionButtons = () => (
	<Fragment>
		<BulkExportButton />
		<BulkDeleteButton />
	</Fragment>
);

const clientFilters = [
	<TextInput label="Search" source="q" alwaysOn />,
	<TextInput label="Email" source="email" />,
	<SegmentsInput alwaysOn />,
];

const ClientStatus = () => {
	const record = useRecordContext();
	const { data: openHatRequests, isLoading: openHatRequestsLoading } =
		useOpenHAT({ clientId: record.id });
	if (openHatRequestsLoading) return <CircularProgress />;
	return openHatRequests?.length ? (
		<Tooltip title="Bloqué par référence HAT">
			<WarningIcon />
		</Tooltip>
	) : (
		""
	);
};

const ClientListActions = () => (
	<TopToolbar>
		<FilterButton />
		<CreateButton />
		<ExportButton maxResults={100000} />
	</TopToolbar>
);

const ClientList = (props) => (
	<List
		sort={{ field: "updatedAt", order: "DESC" }}
		filters={clientFilters}
		actions={<ClientListActions />}
		{...props}
	>
		<Datagrid
			bulkActionButtons={<ClientBulkActionButtons />}
			isRowExpandable={(row) => row.family.data}
			expand={<RelatedClients endpoint="families" />}
			sx={{
				"& .RaDatagrid-expandedPanel > .MuiTableCell-body": {
					padding: "0px",
				},
			}}
			//Trigger edit on rowClick
			rowClick="edit"
		>
			<DateField label="Created" source="createdAt" showTime />
			<DateField label="Updated" source="updatedAt" showTime />
			<ClientNameField label="Name" source="last_name" />
			<TextField source="email" />
			<ClientStatus source="" label="" />
			<EditButton label="Details" />
		</Datagrid>
	</List>
);

export default ClientList;
