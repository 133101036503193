import {
	TextInput,
	ReferenceInput,
	ReferenceArrayInput,
	SelectInput,
	BooleanInput,
	Form,
	SaveButton,
	DeleteButton,
	required,
	AutocompleteInput,
	AutocompleteArrayInput,
} from "react-admin";
import {
	RichTextInput,
	RichTextInputToolbar,
	FormatButtons,
} from "ra-input-rich-text";
import { Typography, Box, Toolbar } from "@mui/material";
import { useWatch } from "react-hook-form";

const TargetSelect = (props) => {
	const email_type = useWatch({ name: "email_type", control: props.control });
	return email_type === "targeted" ? (
		<ReferenceInput source="segment" reference="segments">
			<AutocompleteInput optionText="name" label="Segment" />
		</ReferenceInput>
	) : (
		<ReferenceArrayInput
			source="service_groups"
			reference="service-groups"
			fullWidth
		>
			<AutocompleteArrayInput optionText="name" label="Services" />
		</ReferenceArrayInput>
	);
};

const RecipientSelect = (props) => {
	const email_type = useWatch({ name: "email_type", control: props.control });
	return email_type === "targeted" ? (
		<SelectInput
			source="segment_recipient"
			resource="emails"
			label="Primary Recipient"
			choices={[
				{ id: "client", name: "Send to client" },
				{ id: "contact", name: "Send to contact (e.g. Staff/Provider)" },
			]}
			defaultValue={"client"}
			validate={[required()]}
			fullWidth
		/>
	) : null;
};

export const EmailForm = (props) => {
	return (
		<Form {...props}>
			<Box p="1em">
				<Box p="1em 0em">
					<Typography variant="h5">Email</Typography>
				</Box>
				<TextInput source="name" resource="emails" label="Name" fullWidth />
				<TextInput
					source="description"
					resource="emails"
					label="Description"
					multiline
					fullWidth
				/>

				<Box p="1em 0em">
					<Typography variant="h5">Recipients</Typography>
				</Box>
				<Box display="flex" m="0em -0.5em">
					<Box flex="33.33%" p="0em 0.5em">
						<SelectInput
							source="email_type"
							resource="emails"
							label="Email Type"
							choices={[
								{ id: "targeted", name: "Send to segment" },
								{ id: "referral", name: "Service request" },
							]}
							validate={[required()]}
							fullWidth
						/>
					</Box>
					<Box flex="33.33%" p="0em 0.5em">
						<TargetSelect />
					</Box>
					<Box flex="33.33%" p="0em 0.5em">
						<RecipientSelect />
					</Box>
				</Box>

				<Box p="1em 0em">
					<Typography variant="h5">Additional Recipients</Typography>
					<Typography>
						When an email is triggered by the above targeting, you can
						optionally send a copy of the email to the following recipients.
					</Typography>
				</Box>

				<Box display="flex" m="0em -0.5em">
					<Box flex="50%" p="0em 0.5em">
						<TextInput
							source="recipients"
							resource="emails"
							label="Recipients (comma seperate multiple)"
							fullWidth
						/>
					</Box>
				</Box>

				<Box p="1em 0em">
					<Typography variant="h5">Message</Typography>
				</Box>
				<TextInput
					source="subject"
					resource="emails"
					label="Subject"
					fullWidth
					validate={[required()]}
				/>

				<RichTextInput
					source="body"
					label="Body"
					multiline
					fullWidth
					height="450px"
					sx={{ height: "450px", width: "100%" }}
					toolbar={
						<RichTextInputToolbar>
							<FormatButtons size="small" />
						</RichTextInputToolbar>
					}
				/>

				<Box p="1em 0em">
					<Typography variant="h5">Access &amp; Reporting</Typography>
				</Box>
				<Box display="flex">
					<Box flex="100%">
						<BooleanInput
							source="link_to_record"
							resource="emails"
							label="Include link to record?"
						/>
						<BooleanInput
							source="link_to_form"
							resource="emails"
							label="Include link to partial form?"
						/>
					</Box>
				</Box>
			</Box>
			<Toolbar>
				<Box display="flex" justifyContent="space-between" width="100%">
					<SaveButton />
					<DeleteButton />
				</Box>
			</Toolbar>
		</Form>
	);
};

export default EmailForm;
