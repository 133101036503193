import { Create } from "react-admin";
import ClientFormCreate from "./ClientFormCreate";

const ClientCreate = () => (
	<Create>
		<ClientFormCreate />
	</Create>
);

export default ClientCreate;
